import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { NgIf } from '@angular/common';
import { NgIcon } from '@ng-icons/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-image-dialog',
    templateUrl: './image-dialog.component.html',
    styleUrls: ['./image-dialog.component.scss'],
    imports: [
        MatButtonModule,
        NgIcon,
        NgIf,
    ]
})
export class ImageDialogComponent implements OnInit {
  @Input()
  nome: string;

  imagemPath: any;
  imagemBase64: string;
  imagemTipo: string;

  constructor(
    public dialogRef: MatDialogRef<ImageDialogComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    if (this.data) {
      if (this.data.imagemPath) this.imagemPath = this.data.imagemPath;
      else {
        this.imagemBase64 = this.data.imagemBase64;
        this.imagemTipo = this.data.imagemTipo;
        this._prepararImagem();
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

  private _prepararImagem() {
    this.imagemPath = this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:image/${this.imagemTipo};base64,` + this.imagemBase64
    );
  }
}
